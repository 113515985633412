import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const API_URLS = {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  // ARCHVIO DE CONFIGURACIÓNN
  getData: 'data.json',
  // CART
  getCategories: '/api/supercategorias/get',
  getSubCategories: '/api/categorias/getBySuperCategoria/',
  getServices: '/api/servicios/getByCategorias/',
  checkDomain: '/api/dominios/check',
  checkDomainExist: '/api/dominios/exists/',
  sendCartTo: '/api/habilitaciones/contratar', //POST
  sendResumeTo: '/api/notacobros/contratar', //POST
  checkNotaCobros: '/api/notacobros/verificar_notacobros',
  verifyTurnstile: '/api/verifyUser/turnstile', //verifyUser/turnstile
  sendMessage: '/api/sendMessage',
  getService: '/api/servicios/getByNotaCobro/',
  getUniqueService: '/api/servicios/getUniqueService/',
  getNotasCobroResumenPago: '/api/notacobros/getNotasCobroResumenPago',

  // USER
  login: '/api/clientes/getCliente', // POST
  register: '/api/clientes/create', // POST
}

export default new Vuex.Store({
  // modules: {}
  state: {
    // CONFIGURACIÓN
    data: null,
    // GENERALES
    categories: [],
    subCategories: [],
    services: [],
    service: [],
    serviceList: {
      notasVencidas: false
    },

    // CART
    cart: [],
    selected: null,

    // DETAIL
    detail: {},

    //USER
    user: null,

    // WEBPAY
    resume: [],
    token: null,
    status: null,
    // NotasCobradas: [],
  },
  getters: {
    // CONFIGURACIÓN
    getData(state) {
      return state.data
    },
    // GENERALES
    getCategories_(state) {
      return state.categories
    },
    getSubCategories_(state) {
      return state.subCategories
    },
    getServices_(state) {
      return state.services
    },

    getService_(state) {
      return state.service
    },
    getServiceList(state) {
      return state.serviceList
    },
    // CART
    getCart(state) {
      return state.cart
    },
    getSelected(state) {
      return state.selected
    },
    // DETAIL
    getDetail(state) {
      return state.detail
    },

    getConfigService(state) {
      return state.detail.configuracion
    },

    // USER
    getUser(state) {
      return state.user
    },

    // RESUMEN
    getResume(state) {
      return state.resume
    },
    getToken(state) {
      return state.token
    },
    getStatus(state) {
      return state.status
    },
    // getNotasCobradasResumen(state){
    //   return state.NotasCobradas;
    // },

  },
  mutations: {
    //CONFIGURACIÓN
    setData(state, data) {
      state.data = data;
      // console.log("se ejecuta setData y se guarda la data");
    },
    // GENERALES
    setCategories(state, categories) {
      state.categories = categories
    },
    setSubCategories(state, categories) {
      state.subCategories = categories
    },
    setServices(state, services) {
      state.services = services
    },
    setService(state, service) {
      state.service = service
    },
    setServiceList(state, services) {
      // console.log("setServiceList");
      state.serviceList = services
    },
    
    clearCategory(state) {
      state.subCategories = []
      state.services = []
    },

    // CARRITO
    addServiceToCart(state, service) {
      var flag = state.cart.find(servicio => servicio.id == service.id)
      if (flag && service.producto) {
        var index = state.cart.findIndex(servicio => servicio.id == service.id)
        if (flag.configuracion.cantidad) {
          state.cart[index]['configuracion']['cantidad'] = parseInt(state.cart[index]['configuracion']['cantidad']) + parseInt(service.configuracion.cantidad)
        } else {
          state.cart[index]['configuracion']['cantidad'] = service.configuracion.cantidad
        }

      } else {
        state.cart.push(service)
      }
    },
    updateService(state, params) {
      state.cart[params.index] = params.service
    },
    deleteService(state, service) {
      state.cart.splice(service, 1)
    },
    clearCart(state) {
      state.cart = []
    },
    setSelected(state, id) {
      // console.log("setSelected: id: ", id);
      state.selected = id
    },
    // Eliminar del carrito ya establecido los servicios adicionales - Complementos
    deleteServiceAditionalCart(state,detail){
      console.log("deleteServiceAditionalCart: ", detail);
      // console.log("deleteServiceAditionalCart: detail: ", detail);
        let domainDelete = detail.configuracion[0].value;
        state.cart.forEach(cartItem=>{
          if(detail.descripcion == 'Dominio .COM' ){
            // console.log("if Dominio .COM");
            cartItem.servicios_adicionales.forEach((aditional,index)=>{
              if(aditional.descripcion == 'Dominio .COM' && aditional.configuracion[0].value == domainDelete ){
                // console.log("index: ", index);
                // en caso de que el servicio adicional sea un dominio a comprar, este se cambia de la configuracion del servicio a comprar por 'tengo  mi dominio'
                if(cartItem.configuracion[1].value == 'Comprar nuevo dominio'){
                  console.log("deleteServiceAditionalCart: es un nuevo dominio");
                  state.detail.configuracion[1].value = 'Tengo mi dominio ';
                }
                cartItem.servicios_adicionales.splice(index,1);
              }
            })
            // cartItem.servicios_adicionales = cartItem.servicios_adicionales.filter(el=> el.configuracion[0].value != domainDelete && el.descripcion != detail.descripcion );
          }else{
            // console.log("deleteServiceAditionalCart else");
            cartItem.servicios_adicionales = cartItem.servicios_adicionales.filter(el=> el.configuracion[0].value != domainDelete );
          }
        })
    },

    // DETALLE

    addServiceToDetail(state, service) {
      state.detail = service
    },
    addIndexServiceToDetail(state, index) {
      Vue.set(state.detail, 'pos', index)
    },
    updateServiceDetail(state, params) {
      state.detail.configuracion = []
      for (const [key, value] of Object.entries(params.configuracion)) {
        state.detail.configuracion.push(value)
      }

      Vue.set(state.detail, 'nombre_', params.nombre_)
      Vue.set(state.detail, 'domain_', params.domain_)
      Vue.set(state.detail, 'estado', params.estado)

    },
    addServiceToDetailAdicional(state, service) {
      // console.log("addServiceToDetailAdicional: service", service)
      var flag = state.detail.servicios_adicionales.find(servicio => servicio.id == service.id)
      if (flag) {
        // console.log("flag es true");
        var index = state.detail.servicios_adicionales.findIndex(servicio => servicio.id == service.id)
        if (flag.configuracion.cantidad) {
          state.detail.servicios_adicionales[index]['configuracion']['cantidad'] = state.detail.servicios_adicionales[index]['configuracion']['cantidad'] + service.configuracion.cantidad
        } else {
            if(service?.configuracion[0].domain){
              state.detail.servicios_adicionales.push(service)
            }else{
              if(service.cuantificable == 1){
                state.detail.servicios_adicionales.push(service)
              }else{
                Vue.set(state.detail.servicios_adicionales[index], 'nombre_', service.nombre_)
                state.detail.servicios_adicionales[index]['configuracion'] = service.configuracion
              }
              
            }
         
        }

      } else {
        //  console.log("addServiceToDetailAdicional: else service se agrega: ", service)
        state.detail.servicios_adicionales.push(service)
      }
    },

    deleteDetail(state, payload) {
      const {service, index} = payload;

      if(service.categoria.nombre == 'Alias de Dominio' && service.configuracion.length > 1){
      // console.log("2.deleteDetail, Alias de dominio: ", service.categoria.nombre);

        let domainDelete = service.configuracion[0].value;
        const indicesABorrar = state.detail.servicios_adicionales.reduce((acc, servicio, index) => {
          if (servicio.configuracion[0].value === domainDelete) {
            acc.push(index);
          }
          return acc;
        }, []);
        let counter = 0;
        indicesABorrar.forEach((indice) => {
          let indiceFormat = indice == 0 ? 0 : (counter > 0 ? indice - 1 : indice) 
          state.detail.servicios_adicionales.splice(indiceFormat, 1);
          counter++
        });

      }else{
        // console.log("servicios_adicionales: ", state.detail.servicios_adicionales[index]);
        if(state.detail.servicios_adicionales[index].nombre = 'Dominio .COM' && state.detail.configuracion[1].value == 'Comprar nuevo dominio' ){
          console.log("servicio adicional es dominio .com");
          state.detail.servicios_adicionales.splice(index, 1)
        }
        state.detail.configuracion[1].value = 'Tengo mi dominio ';
      }

      // let deleteAdicional = {domain: "", tipo: ""};

      // service.configuracion.forEach(el=> {
      //   if(el.value == 'Comprar nuevo dominio'){
      //     deleteAdicional.tipo = el.value;
      //     deleteAdicional.domain = service.domain_;
      //   }
      // });

      // if(deleteAdicional.domain != ""){
      //   console.log("deleteDetail: deleteAdicional: ", deleteAdicional);
      //   console.log("deleteDetail: adicionales: ", state.detail.servicios_adicionales);
      //   state.detail.servicios_adicionales = state.detail.servicios_adicionales.filter(e=> e.configuracion[0].value != deleteAdicional.domain );
      //   state.detail.servicios_adicionales = state.detail.servicios_adicionales.filter(e=> e.domain_ != deleteAdicional.domain );
      //   // state.detail.servicios_adicionales.splice(service, 1);
      //   console.log("deleteDetail: state.detail.servicios_adicionales: ", state.detail.servicios_adicionales);

      // }else{
      //   console.log("deleteDetail: else: borrado normal");
      //   state.detail.servicios_adicionales.splice(service, 1);
      // }

    },

    clearDetail(state) {
      // console.log("clearDetail");
      state.detail = {}
    },


    // USUARIO
    setUser(state, user) {
      state.user = user
    },
    logout(state) {
      state.user = null;
      location.reload();
    },

    // WEBPAY
    setResume(state, resume) {
      state.resume = resume;
    },

    updateStateResume(state) {
      state.resume.forEach((value, index) => {
        state.resume[index]['notacobro']['estado'] = 1
      })
    },
    updateStatus(state, status) {
      state.status = status
    },

    clearResume(state) {
      console.log("clearResume");
      state.resume = []
    },

    setToken(state, token) {
      state.token = token
    },

    clearPagoRapido(state){
      state.resume = [];
      state.services = [];
      state.service = [];
      state.serviceList = {
        notasVencidas: false

      };
    },
    addCuponService(state, cupones){
      // 1. Limpiar TODOS los cupones existentes (evitar sobreescritura)
      state.cart.forEach(service => {
        Vue.delete(service, 'cupon');
        if (service.cupon_service) Vue.delete(service, 'cupon_service');
    
        if (service.servicios_adicionales) {
          service.servicios_adicionales.forEach(adicional => {
            Vue.delete(adicional, 'cupon');
            if (adicional.cupon_service) Vue.delete(adicional, 'cupon_service');
          });
        }
      });
      // 2. Aplicar SOLO los cupones recibidos en el array
      cupones.forEach(cupon => {
        const servicio = state.cart.find(service => service.id === cupon.id_service);
        if (servicio) {
          Vue.set(servicio, 'cupon', cupon.cupon);
        }
        // Aplicar a servicios adicionales si existen
        state.cart.forEach(service => {
          if (service.servicios_adicionales) {
            const adicional = service.servicios_adicionales.find(a => a.id === cupon.id_service);
            if (adicional) {
              Vue.set(adicional, 'cupon', cupon.cupon);
            }
          }
        });
      });
    },
  // delete cupon validado
  deleteCuponService(state, cuponAplied){
    state.cart.forEach(service=>{
      if( service.cupon &&  cuponAplied == null || service.cupon && service.id == cuponAplied.id_service){
        Vue.delete(service, 'cupon'); //eliminar cupon validado
        if(service.cupon_service) Vue.delete(service, 'cupon_service'); //eliminar cupon fantasma
      }

      // si existen servicios adicionales buscarlos para eliminar cupon
      if(service.servicios_adicionales){
        service.servicios_adicionales.forEach((adicional,index_adicional)=>{
          if(adicional.cupon && cuponAplied == null || adicional.cupon && adicional.id == cuponAplied.id_service ){
            Vue.delete(adicional, 'cupon'); //eliminar cupon validado
            if (adicional.cupon_service) Vue.delete(adicional, 'cupon_service');
          }
        });
      }
    });
  },
  // delete cupon fantasma (no validado)
  deleteCuponFantasmaService(state, cuponAplied){
    state.cart.forEach(service=>{
      if(service.cupon_service){
        delete service.cupon_service;
      }

      if(service.servicios_adicionales){
        service.servicios_adicionales.forEach((adicional,index_adicional)=>{
          if(adicional.cupon_service){
            delete adicional.cupon_service;
          }
        });

      }
    });
  }
  },
  actions: {
    //CONFIGURACIÓN
    getData(context) {
      // console.log("getData primera solicitud");
      let url = API_URLS.getData //ANCHOR[id=ALL-DATA]

      return axios.get(url).then(res => {
        // console.log("getData response: ", res.data);
        let data = res.data
        context.commit('setData', data)
      }).catch((error) => {
        console.log(error.response)
      })
    },
    // CARRITO
    getCategories(context) {
      let url = API_URLS.baseUrl + API_URLS.getCategories //ANCHOR[id=ALL-DATA]

      return axios.get(url).then(res => {
        let categories = res.data.supercategorias
        context.commit('setCategories', categories)
      }).catch((error) => {
        console.log(error)
      })
    },
    getSubCategories(context, category) {
      let url = API_URLS.baseUrl + API_URLS.getSubCategories + category//ANCHOR[id=ALL-DATA]

      return axios.get(url).then(res => {
        let categories = res.data.categorias
        // agregar el plan emprendedor de primero
        let categoriaOrdenada = categories.sort((a, b) => (a.categoria.id === 76 ? -1 : b.categoria.id === 76 ? 1 : 0));
        context.commit('setSubCategories', categoriaOrdenada);
      }).catch((error) => {
        console.log(error.response)
      })
    },
    // getNotasCobradas(context, notas){
    //   let url = API_URLS.baseUrl + API_URLS.getNotasCobroResumenPago + `/${notas}`;
    //   axios.get(url).then(res=>{
    //     console.log("res", res)
    //     context.commit('setNotasCobradas',res.data);
    //   }).catch(error=>{
    //     console.log("eeror: ", error);
    //   });
    // },
    
    getService(context, params) {
      // console.log("getService index, params: ", params);
      let token;
      params.tokenTurnstile ? token = `?recaptcha_response=${params.tokenTurnstile['cf-turnstile-response']}` : token = '';     
      let url = API_URLS.baseUrl + API_URLS.getService + params.nota_cobro + `/${params.clienteEmailRut}${token}`; //ANCHOR[id=ALL-DATA]
      console.log("getService, index, url: ", url);
      return axios.get(url).then(res => {
        if(res.data.notasVencidas){
        let services = res.data;
        context.commit('setServiceList', services);
        }else{
          let service = res.data
          context.commit('setService', service)
        }
      }).catch((error) => {
        console.log("catch hay un error ------------ : ",error.response);
      })
    },
    getServices(context, category) {
      // console.log("getServices category: ",category );
      // console.log("se ejecuta getServices: category: ", category);
      let id_user = null;
      if(context.getters.getUser && context.getters.getUser.id){
        // console.log('getServices: ', context.getters.getUser);
        id_user = context.getters.getUser.id;
      }
      // let url = API_URLS.baseUrl + API_URLS.getServices + `/id=${category}/?id_user=${id_user}`//ANCHOR[id=ALL-DATA]
      let url = `${API_URLS.baseUrl}${API_URLS.getServices}${category}/${id_user}`;
        
      return axios.get(url).then(res => {
        let services = res.data.servicios;
        // console.log("res getServices obtenidos del backerd: ", res.data);
        // console.log("se ejecuta getServices: axios response: ", services);
        context.commit('setServices', services)
        return services
      }).catch((error) => {
        console.log(error.response)
      })
    },
    getUniqueService(context, id_service){
      let id_user = null;
      if(context.getters.getUser && context.getters.getUser.id){
        id_user = context.getters.getUser.id;
      }
      let url = `${API_URLS.baseUrl}${API_URLS.getUniqueService}${id_service}/${id_user}`;
        
      return axios.get(url).then(res => {
        let services = res.data.servicio;
        return services
      }).catch((error) => {
        console.log(error.response)
      })
    },
    getServices_() {
      let url = API_URLS.baseUrl + API_URLS.getServices + 5 + `/null` //ANCHOR[id=ALL-DATA]

      return axios.get(url).then(res => {
        return res.data.servicios
      }).catch((error) => {
        console.log(error.response)
      })
    },

    checkDomain(context, params) {
      let url = API_URLS.baseUrl + API_URLS.checkDomain//ANCHOR[id=ALL-DATA]

      return axios.post(url, params).then(res => {
        return res
      }).catch((error) => {
        console.log(error.response)
        return error.response.data
      })
    },
    checkDomainExist(context, domain) {
      let url = API_URLS.baseUrl + API_URLS.checkDomainExist + domain //ANCHOR[id=ALL-DATA]

      return axios.get(url).then(res => {
        return res.data
      }).catch((error) => {
        console.log(error.response)
        return error.response.data
      })
    },

    sendCartTo(context, metodo) {
      let url = API_URLS.baseUrl + API_URLS.sendCartTo//ANCHOR[id=ALL-DATA]

      let params = {
        cliente: context.getters.getUser,
        carrito: context.getters.getCart,
        metodo_pago: metodo
      }
      console.log("1.sendCartTo params sendtOCART: ", params);

      return axios.post(url, params).then(res => {
      console.log("2.sendCartTo res: ", res);
        
        return res.data
      }).catch((error) => {
        console.log(error.response)
        return error.response
      })
    },

    sendCartToQuickPay(context) {
      let url = API_URLS.baseUrl + API_URLS.sendResumeTo//ANCHOR[id=ALL-DATA]

      let params = {
        notascobro: context.getters.getUser,
        carrito: context.getters.getCart,
      }

      return axios.post(url, params).then(res => {
        return res.data
      }).catch((error) => {
        console.log(error.response)
        return error.response
      })
    },

    sendResumeTo(context, dataPay) {

      let url = API_URLS.baseUrl + API_URLS.sendResumeTo//ANCHOR[id=ALL-DATA]
      let params = { notacobros: dataPay.notascobro };
      // preguntar si pago es con cupon, de ser asi se envia a la api para que lo procese
      if(dataPay.esPagoPorCupon && dataPay.esPagoPorCupon != null) params.esPagoPorCupon = dataPay.esPagoPorCupon;
      console.log("sendResumeTo: ", params);
      return axios.post(url, params).then(res => {
        return res.data
      }).catch((error) => {
        console.log(error)
        return error.response
      })
    },

    checkNotaCobros(context, notas) {
      let url = API_URLS.baseUrl + API_URLS.checkNotaCobros //ANCHOR[id=ALL-DATA]
      let params = {
        params: {
          notacobros: notas
        }
      }

      return axios.get(url, params).then(res => {
        return res
      }).catch((error) => {
        console.log("checkNotaCobros error ",error.response)
        return error.response.data
      })
    },

    sendMessage(context, params) {
      let url = API_URLS.baseUrl + API_URLS.sendMessage //ANCHOR[id=ALL-DATA]

      return axios.post(url, params).then(res => {
        return res
      }).catch((error) => {
        console.log(error.response)
        return error.response.data.errors
      })
    },

    // USUARIO (LOGIN/REGISTER)
    login(context, params) {
      let url = API_URLS.baseUrl + API_URLS.login//ANCHOR[id=ALL-DATA]

      return axios.post(url, params).then(res => {
        let user = res.data.cliente
        context.commit('setUser', user);
        return res.data;
      }).catch((error) => {
        console.log("error login",error)
        return error.response
      })
    },

    register(context, params) {
      let url = API_URLS.baseUrl + API_URLS.register//ANCHOR[id=ALL-DATA]

      return axios.post(url, params).then(res => {
        let user = res.data.cliente[0]
        context.commit('setUser', user)
        return res.data

      }).catch((error) => {
        console.log(error.response)
        return error.response.data.errors
      })
    },
  },
  plugins: [
    createPersistedState({
      paths: ["cart", "user", "resume", "token", "status"]
    })
  ]

})

