<template>
  <div>
    <div style="text-align: left; position: relative; top: -30px">
      <div class="row">
        <div class="col-md-12 col-lg-12" style="margin-top: 25px">
          <div class="sec-main sec-bg1 tabs" style="background: transparent; box-shadow: none; padding: 0">
            <div class="col-md-12 col-lg-12 px-0" style="min-height: 53px; position: inherit !important;">
              <div v-show="!getUser" class="pt-tabs-1" style="margin-top: 60px;">
                <div
                  class="nav nav-tabs nav-fill nav-resp-tab"
                  id="nav-tab"
                  role="tablist"
                  style="z-index: 10"
                >
                  <a
                  @click="activeColorAuth('login')"
                    class="pt-tabs nav-item nav-link  nav-item2 tabs-new tab-resp-v tab-resp col-md-12"
                    :class="colorAuthBoton == 'login' ? 'colorAuthBoton': '' "

                    id="nav-home-1"
                    data-toggle="tab"
                    href="#nav-1"
                    aria-controls="nav-home-1"
                    style="border: 1px solid var(--primary-color)"
                  >
                    <div>
                      <span style="display: block !important">Tengo una cuenta</span>
                    </div>
                  </a>

                  <a
                  @click="activeColorAuth('signup')"
                    class="pt-tabs nav-item nav-link active show nav-item2 tabs-new col-md-12 tab-resp"
                    :class="colorAuthBoton == 'signup' ? 'colorAuthBoton': '' "
                    id="nav-home-0"
                    data-toggle="tab"
                    href="#nav-0"
                    aria-controls="nav-home-0"
                    style="border: 1px solid var(--primary-color)"
                  >
                    <div>
                      <span style="display: block !important">Crear nueva cuenta</span>
                      <!-- <span style="display: block !important">{{$route.fullPath}}</span> -->

                    </div>
                  </a>

                  <a
                    v-if="!getUser"
                    class="pt-tabs nav-item2 tabs-new col-md-12 tab-resp register_absolute"
                    :style="colorAuthBoton == 'login' ? 'opacity: 1;' : 'opacity: 0; cursor: default' "
                    id="nav-home-0"
                    data-toggle="tab"
                    href="#nav-0"
                    aria-controls="nav-home-0"
                    @click="activeColorAuth('signup')"
                    style="margin-top: 8px; position: absolute; text-align: center; color: var(--primary-color);"
                    >
                    <div>
                     <span style="display: block !important; font-size: 16px;">¿Sin cuenta aún? Regístrate ya y comienza a disfrutar de nuestros servicios.</span>
                    </div>
                  </a>

                </div>
              </div>

              <div v-show="getUser" class="pt-section-title-1 mb-0" style="height: 107px">
                <h5 class="pt-section-title mb-0">¡Ya casi es <span class="imp-word mb-0">tuyo!</span></h5>
              </div>
            </div>

            <div class="col-md-12 col-lg-12 px-0">
              <header-nav-component
                :title="'Servicios por activar'"
                :back_="true"
                :loading="true"
                :step="4"
                @back="back"
                @goToCategory="goToCategory"
                style="padding-top: 0px; padding-bottom: -5px; margin-bottom: 25px"
              />
            </div>

            <div>
              <div class="col-md-12 px-0">
                <div id="order-standard_cart" style="line-height: 1.4 !important">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-0">

                        <div v-if="cart.length != 0 && getUser && tieneHosting" class="row">
                            <div class="col-md-12">
                              <div class="alert alert-warning" style="min-height: 52px; padding: 15px 30px" role="alert">
                                <span v-if="getUser && cart.length != 0">
                                  Para probar el servicio de hosting, selecciona Transferencia y posteriormente Activar Servicio. Recuerda que tienes 5 días sin costo de prueba y si requieres asistencia puedes contáctarnos a través de nuestros canales de atención al cliente.
                                </span>
                              </div>
                            </div>
                          </div>

                        <div class="view-cart-items-header">
                          <div class="row" style="flex-flow: row nowrap">
                            <div class="col-lg-7 col-sm-7 col-xs-7">Producto/Opciones</div>
                            <div class="col-lg-4 col-sm-5 text-right title-r-v">Precio/Ciclo</div>
                          </div>
                        </div>

                        <div v-if="cart.length != 0" class="view-cart-items">
                          <div v-for="(item, index) in returnListAllServices" :key="`${index}-${generateRandomKey()}`" class="item box_service">
                            <div class="row">
                              <button
                                v-if="item.nivel == 2"
                                @click="deleteServiceAditional(item)"
                                  class="btn btn-link btn-xs fix-btn-table-c-v btn-color-delete-v btn-remove-class box_service_btn_deleteService"
                                >
                                <i class="fas fa-times"></i>
                              </button>
                              <div class="col-md-7">
                                <span style="display: block">
                                  <div style="font-size: 1.6em">
                                    <b v-if="item.configuracion.cantidad" class="count-dynamic">{{
                                      returnQuantityService(item.configuracion)
                                    }}</b
                                    >{{ item.nombre }}
                                    <button
                                      v-if="item.nivel == 1 && !item.producto"
                                      alt="Editar servicio" aria-label="Editar servicio"
                                      @click="editItem(item.pos_cart)"
                                      class="btn btn-link btn-xs fix-btn-table-c-v ml-1 btn-color-edit-v"
                                    >
                                      <i class="fa fa-pencil-square-o"></i>
                                      Editar
                                    </button>

                                    <button
                                      v-if="item.nivel == 1"
                                      @click="setSelected(item.pos_cart)"
                                      alt="Eliminar servicio" aria-label="Eliminar servicio"
                                      v-tooltip="'Eliminar servicio'"
                                      data-toggle="modal"
                                      data-target="#myModal"
                                      class="btn btn-link btn-xs fix-btn-table-c-v delete-btn-2 btn-color-delete-v"
                                    >
                                      <i class="fas fa-times"></i>
                                      
                                    </button>
                                  </div>
                                </span>
                                <!-- start aditional  -->
                                <span v-if="item.subcategory_" class="item-group">{{
                                  item.subcategory_.nombre
                                }}</span>
                                <span v-else class="item-group"> Complemento </span>

                                <div v-if="!item.configuracion.cantidad && !item.producto" class="mt-1">
                                  <div v-for="(configuracion, name) in item.configuracion" :key="`${name}-${generateRandomKey()}`">
                                    <span class="item-domain" style="color: var(--primary-color)">
                                      {{ configuracion.nombre }}: {{ configuracion.value }}
                                    </span>
                                  </div>
                                </div>
                              </div>  <!-- end aditional  -->
                               

                              <div class="col-md-5 col-lg-4 px-0 pt-pricebox-info price-card-new price-cont">
                                <h6 class="price" style="text-align: right">
                                  <!-- precio con el descuento -->
                                  <div v-if="item.cupon && item.cupon.estado == 1" class="priceAndCupon">
                                    <b v-if="item.cupon.periodicidad_state == 1 && item.cupon.cupon_periodicidad_selected && item.cupon.cupon_periodicidad_selected.nombre" class="priceAndCupon_priceBeforeCupon">
                                      ${{ formatNumber( setPriceService( item.moneda, (item.precio *  get_valor_por_nombre_periodicidad(item.cupon.cupon_periodicidad_selected.nombre) ) * returnCantidadService(item) ) ) }}
                                    </b>
                                    <b v-else class="priceAndCupon_priceBeforeCupon">${{ formatNumber( setPriceService( item.moneda, item.precio * returnCantidadService(item) ) ) }}</b>
                                    <!-- precio sin descuento -->
                                    <span>
                                    ${{formatNumber( setPriceService( item.moneda, precioConDescuento(item) * returnCantidadService(item) ) ) }}
                                    </span>
                                  </div>

                                  <!-- precio con el descuento FANTASMA -->
                                  <div v-else-if="!item.cupon && item.cupon_service" class="priceAndCupon">
                                    <b v-if="item.cupon_service.periodicidad_state == 1 && item.cupon_service.cupon_periodicidad_selected && item.cupon_service.cupon_periodicidad_selected.nombre" class="priceAndCupon_priceBeforeCupon">
                                      ${{ formatNumber( setPriceService( item.moneda, (item.precio *  get_valor_por_nombre_periodicidad(item.cupon_service.cupon_periodicidad_selected.nombre) ) * returnCantidadService(item) ) ) }}
                                    </b>
                                    <b v-else class="priceAndCupon_priceBeforeCupon">${{ formatNumber( setPriceService( item.moneda, item.precio * returnCantidadService(item) ) ) }}</b>
                                    <!-- precio sin descuento -->
                                    <span>
                                    ${{formatNumber( setPriceService( item.moneda, getCalculo_price_with_cupon(item.cupon_service, item.precio) * returnCantidadService(item) ) ) }}
                                    </span>
                                  </div>


                                  <span v-else
                                    >${{
                                      formatNumber( setPriceService( item.moneda, item.precio * returnCantidadService(item) ) )
                                    }}
                                  </span>

                                </h6>

                                <p v-if="item.cupon && item.cupon.periodicidad_state == 1 && item.cupon.cupon_periodicidad_selected">
                                  CLP / {{ item.cupon.cupon_periodicidad_selected.nombre }} - Primer pago
                                </p>
                                <p v-else class="pt-price-duration p-price-duration-v">
                                  CLP / {{ item.periodicidad.nombre }}
                                </p>
                              </div>
                              <button
                                v-if="item.nivel == 1"
                                @click="setSelected(item.pos_cart)"
                                type="button"
                                  class="btn btn-link btn-xs btn-remove-from-cart delete-btn-1 btn-remove-class"
                                  v-tooltip="'Eliminar servicio'"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  style=" position: absolute;top: 0;right: 0; margin: 1px 10px; z-index: 1000; margin: 10px 15px;"
                                >
                                <i class="fas fa-times"></i>
                              </button>
                            </div>

                            <div class="listCart_message_cupon" v-if="item.cupon">
                              <!-- icon cupon start -->
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="sparkle">
                                <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z">
                                </path>
                                <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z">
                                </path>
                                <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z">
                                </path>
                              </svg>
                                    <!-- icon cupon end -->
                              <!-- cupon validado --------------------------------- -->
                              <span v-if="item.cupon.periodicidad_state == 1" class="listCart_message_cupon_text">
                                Promoción primer pago de un cupón {{ item.cupon.cupon_periodicidad_selected.nombre }} del 
                                {{ item.cupon.id_tipocupon == 1 ? `$${ formatNumber(item.cupon.cupon_periodicidad_selected.valor)}` : `${item.cupon.cupon_periodicidad_selected.valor}%` }}

                              </span>
                              <span v-else class="listCart_message_cupon_text">
                                Promoción
                                {{ item.cupon.id_tipocupon == 1 ? ` de $${item.cupon.valor}` : `del ${item.cupon.valor}%` }}
                              </span>

                            <b class="ml-2" v-if="item.cupon && item.cupon.new_client == 1">(Exclusivo para clientes nuevos)</b>

                            </div>

                            <!-- cupon fantasma  ------------------------------------ -->
                            <div v-if="!item.cupon && item.cupon_service" class="listCart_message_cupon">
                                <!-- icon cupon start -->
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="sparkle">
                                <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z">
                                </path>
                                <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z">
                                </path>
                                <path class="path" stroke-linejoin="round" stroke-linecap="round" stroke="black" fill="black" d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z">
                                </path>
                              </svg>
                                    <!-- icon cupon end -->
                                 <span v-if="item.cupon_service.periodicidad_state == 1  && !getUser" class="listCart_message_cupon_text">
                                  Para aplicar el cupón con el descuento ( Primer pago {{ item.cupon_service.cupon_periodicidad_selected.nombre }} ) del 
                                   {{ item.cupon_service.id_tipocupon == 1 ? `$${item.cupon_service.cupon_periodicidad_selected.valor}` : `${item.cupon_service.cupon_periodicidad_selected.valor}%` }} debe iniciar sesión.
                                 </span>

                                 <span v-else-if="item.cupon_service.periodicidad_state != 1 && !getUser" class="listCart_message_cupon_text">
                                  Para aplicar descuento {{ item.cupon_service.id_tipocupon == 1 ? ` de $${item.cupon_service.valor}` : `del ${item.cupon_service.valor}%` }} debe iniciar sesión. 
                                  </span>

                               </div>

                          </div> <!-- END card item  -->
                          
                        </div>

                        <div class="view-cart-items" v-else>
                          <hr />
                          <h5 style="font-size: 14px; letter-spacing: 1px; text-transform: uppercase">
                            Configure al menos un servicio para continuar
                          </h5>
                        </div>

                        <div class="empty-cart">
                          <button
                            v-if="cart.length != 0"
                            alt="Vaciar carrito de compras" aria-label="Vaciar carrito de compras"
                            type="button"
                            class="btn btn-link btn-xs btn-empty-v"
                            style="line-height: 25px !important"
                            id="btnEmptyCart"
                            data-toggle="modal"
                            data-target="#deleteAllModal"
                          >
                            <i class="fas fa-trash-alt icon-empty-v"></i>
                            <span>Vaciar Carro</span>
                          </button>
                        </div>
                      </div>
                      <!-- tab register login cart START ------------------------------------------------- -->
                      <div>
                        <div class="tab-content text-left" id="nav-tabContent">
                          <!-- show login -->
                          <div 
                          v-if="colorAuthBoton == 'login'"
                            class="tab-pane fade" 
                            id="nav-1" 
                            role="tabpanel"
                            aria-labelledby="nav-home-1"
                            >
                            <form
                              @submit.prevent="login_"
                              class="columns column is-multiline is-12"
                              data-vv-scope="form1"
                            >
                              <div v-show="!getUser" class="cd-filter-block">
                                <h4 style="margin-bottom: 10px">Login Usuario Existente</h4>
                                <div class="cd-filter-content">
                                  <div class="row" style="margin-bottom: 20px">
                                    <div class="col-md-6" style="margin-bottom: 10px">
                                      <label><i class="fas fa-envelope"></i></label>
                                      <input
                                        v-model="form1.rut_username"
                                        v-validate="'required'"
                                        type="text"
                                        name="rut_username"
                                        data-vv-as="username o rut"
                                        placeholder="Usuario o RUT (sin puntos y con guión)"
                                        :class="{ 'vue-error': errors.first('form1.rut_username') }"
                                        key="1_"
                                        maxlength="30"
                                      />
                                      <FormError :error="errors.first('form1.rut_username')"> </FormError>
                                    </div>

                                    <div class="col-md-6" style="margin-bottom: 10px">
                                      <label><i class="fas fa-lock"></i></label>
                                      <input
                                        v-model="form1.contrasena"
                                        v-validate="'required'"
                                        type="password"
                                        name="contrasena"
                                        data-vv-as="contraseña"
                                        placeholder="Contraseña"
                                        :class="{ 'vue-error': errors.first('form1.contrasena') }"
                                        key="2_"
                                        maxlength="50"
                                      />
                                      <FormError :error="errors.first('form1.contrasena')"> </FormError>
                                    </div>
                                    <div class="col-md-12 content_loginRegistro">
                                      <button
                                        type="submit"
                                        alt="iniciar session" aria-label="iniciar session"
                                        class="btn btn-default-grad-purple-fill btn-resp-v mb-1 mt-3 content_loginRegistro_ingresar "
                                        style="float: right; padding: 5px 20px !important;"
                                        :class="[verify ? 'verify-class' : 'normal-class']"
                                      >
                                        <span v-if="!verify" class="spinner-span"
                                          >Ingresar
                                          <i
                                            class="fas fa-arrow-alt-circle-right spinner-i"
                                            style="top: 9px"
                                          ></i
                                        ></span>
                                        <div
                                          v-else
                                          class="spinner-border text-secondary"
                                          style="max-height: 30px"
                                          role="status"
                                        >
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                      </button>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>

                            <transition name="slide-fade">
                              <div v-show="getUser" class="row mt-4 pt-2">
                                <div class="col-md-12">
                                  <div
                                    class="alert alert-success"
                                    style="min-height: 52px; padding: 15px 30px"
                                    role="alert"
                                  >
                                    <span v-if="getUser && cart.length != 0"
                                      >Bienvenido {{ getUser.nombre }}! Estas listo para activar
                                      <span v-if="cart.length == 1">tu servicio!</span>
                                      <span v-else>tus servicios!</span></span
                                    >
                                    <span v-else>No tienes ningun servicio para ser activado</span>
                                  </div>
                                </div>
                              </div>
                            </transition>

                          </div>

                          <!-- show register -->
                          <div class="tab-pane fade active show" id="nav-0" role="tabpanel" aria-labelledby="nav-home-0" v-if="!getUser && colorAuthBoton == 'signup'">
                                <form
                                  @submit.prevent="register_"
                                  class="columns column is-multiline is-12"
                                  data-vv-scope="form2"
                                >
                                  <div class="cd-filter-block mb-5">
                                    <h4>Registrarse</h4>
                                    <div class="cd-filter-content">
                                      <div class="title_tabs"><small>Información Personal</small></div>
                                      <div class="row mb-5">
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-user-tie"></i></label>
                                          <input
                                            v-model="form2.nombre"
                                            v-validate="'required'"
                                            name="nombre"
                                            type="text"
                                            placeholder="Nombre Completo"
                                            :class="{ 'vue-error': errors.first('form2.nombre') }"
                                            key="3"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.nombre')"> </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fa fa-id-card-o" aria-hidden="true"></i></label>
                                          <input
                                            v-model="form2.rut"
                                            @keypress="onlyNumberAndDash"
                                            v-validate="'required'"
                                            name="rut"
                                            type="text"
                                            placeholder="18465489-3"
                                            :class="{ 'vue-error': errors.first('form2.rut') }"
                                            key="4"
                                            maxlength="12"
                                          />
                                          <FormError :error="errors.first('form2.rut')"> </FormError>
                                        </div>

                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-envelope"></i></label>
                                          <input
                                            v-model="form2.email"
                                            v-validate="'required|email'"
                                            name="email"
                                            type="text"
                                            placeholder="Email Primario"
                                            :class="{ 'vue-error': errors.first('form2.email') }"
                                            key="5"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.email')"> </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-envelope"></i></label>
                                          <input
                                            v-model="form2.email_tecnico"
                                            v-validate="'required|email'"
                                            name="email_tecnico"
                                            data-vv-as="email técnico"
                                            type="text"
                                            placeholder="Email Técnico"
                                            :class="{ 'vue-error': errors.first('form2.email_tecnico') }"
                                            key="6"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.email_tecnico')"> </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-phone"></i></label>
                                          <input
                                            v-model="form2.telefono"
                                            @keypress="onlyNumber"
                                            v-validate="'required'"
                                            name="telefono"
                                            type="tel"
                                            placeholder="Teléfono"
                                            :class="{ 'vue-error': errors.first('form2.telefono') }"
                                            key="7"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.telefono')"> </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-map-marker-alt"></i></label>
                                          <input
                                            v-model="form2.direccion"
                                            v-validate="'required'"
                                            name="direccion"
                                            data-vv-as="dirección"
                                            type="text"
                                            placeholder="Dirección"
                                            :class="{ 'vue-error': errors.first('form2.direccion') }"
                                            key="8"
                                            maxlength="60"
                                          />
                                          <FormError :error="errors.first('form2.direccion')"> </FormError>
                                        </div>
                                      </div>
                                      <div class="title_tabs title_tabs_infoPersonal">
                                        <small>Datos de Facturación</small>

                                        <div class="empty-cart title_tabs_infoPersonal_empty-cart" style="margin: -20px 0px 0 0">
                                          <a
                                            @click="transferData"
                                            type="button"
                                            class="btn btn-link btn-xs btn-empty-v"
                                            style="line-height: 25px !important"
                                          >
                                            <i class="fas fa-user-tie"></i>
                                            <span> Copiar información personal</span>
                                          </a>
                                        </div>
                                      </div>
                                      <div class="row mb-5">
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-building"></i></label>
                                          <input
                                            v-model="form2.facturacion_nombre"
                                            v-validate="'required'"
                                            name="facturacion_nombre"
                                            data-vv-as="facturación nombre"
                                            type="text"
                                            placeholder="Nombre o Razón Social"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_nombre') }"
                                            key="9"
                                            maxlength="100"
                                          />

                                          <FormError :error="errors.first('form2.facturacion_nombre')">
                                          </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="far fa-building"></i></label>
                                          <input
                                            v-model="form2.facturacion_rut"
                                            @keypress="onlyNumberAndDash"
                                            v-validate="'required'"
                                            name="facturacion_rut"
                                            data-vv-as="facturación rut"
                                            type="text"
                                            placeholder="18465489-3"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_rut') }"
                                            key="10"
                                            maxlength="12"
                                          />
                                          <FormError :error="errors.first('form2.facturacion_rut')"> </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-envelope"></i></label>
                                          <input
                                            v-model="form2.facturacion_email"
                                            v-validate="'required|email'"
                                            name="facturacion_email"
                                            data-vv-as="facturación email"
                                            type="text"
                                            placeholder="Email facturación"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_email') }"
                                            key="11"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.facturacion_email')">
                                          </FormError>
                                        </div>
                                        <div class="col-md-6">
                                          <label><i class="fas fa-phone"></i></label>
                                          <input
                                            v-model="form2.facturacion_telefono"
                                            v-validate="'required'"
                                            name="facturacion_telefono"
                                            @keypress="onlyNumber"
                                            data-vv-as="facturación teléfono"
                                            type="text"
                                            placeholder="Teléfono facturación"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_telefono') }"
                                            key="12"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.facturacion_telefono')">
                                          </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-map-marker-alt"></i></label>
                                          <input
                                            v-model="form2.facturacion_direccion"
                                            v-validate="'required'"
                                            name="facturacion_direccion"
                                            data-vv-as="facturación dirección"
                                            type="text"
                                            placeholder="Dirección facturación"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_direccion') }"
                                            key="13"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.facturacion_direccion')">
                                          </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <select id="facturacion_region"
                                          v-model="form2.facturacion_region"
                                            v-validate="'required'"
                                            name="facturacion_region"
                                            data-vv-as="facturación region"
                                            type="text"
                                            class="aparence-none-v input form-control"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_region') }"
                                            key="14"
                                            maxlength="50"
                                            placeholder="Region facturación"
                                            @change="City_Updater()"
                                            list="facturacion_region"
                                            style="border: none; background: var(--grey-color);"
                                          >
                                            <option value="" disabled selected>Región facturación</option>
                                            <option v-for="(region, idx) in Regiones_" :value="region" :key="idx">
                                              {{ region }}
                                            </option>
                                          </select>
                                          <FormError :error="errors.first('form2.facturacion_region')">
                                          </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <select
                                            v-model="form2.facturacion_comuna"
                                            v-validate="'required'"
                                            name="facturacion_comuna"
                                            data-vv-as="facturación comuna"
                                            type="text"
                                            class="aparence-none-v input form-control"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_comuna') }"
                                            key="15"
                                            maxlength="50"
                                            placeholder="Comuna facturación"
                                            @change="City_Check()"
                                            list="facturacion_comuna"
                                            id="facturacion_comuna"
                                            style="border: none; background: var(--grey-color);"
                                            >
                                            <option value="" disabled selected>Comuna facturación</option>
                                            <option v-for="(comuna, idx) in Comunas_" :value="comuna" :key="idx">
                                              {{ comuna }}
                                            </option>
                                          </select>
                                          <FormError :error="errors.first('form2.facturacion_comuna')">
                                          </FormError>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-building"></i></label>
                                          <input
                                            v-model="form2.facturacion_giro"
                                            v-validate="'required'"
                                            name="facturacion_giro"
                                            data-vv-as="facturación giro"
                                            type="text"
                                            placeholder="Giro facturación"
                                            :class="{ 'vue-error': errors.first('form2.facturacion_giro') }"
                                            key="16"
                                            maxlength="40"
                                          />
                                          <FormError :error="errors.first('form2.facturacion_giro')">
                                          </FormError>
                                        </div>





                                      </div>
                                      <div class="title_tabs"><small>Cuenta y Seguridad</small></div>
                                      <div class="row mb-5">
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-user-tie"></i></label>
                                          <input
                                            v-model="form2.username"
                                            v-validate="'required'"
                                            name="username"
                                            type="text"
                                            placeholder="Usuario o RUT (sin puntos y con guión)"
                                            :class="{ 'vue-error': errors.first('form2.username') }"
                                            key="14"
                                            maxlength="30"
                                          />
                                          <FormError :error="errors.first('form2.username')"> </FormError>
                                        </div>
                                        <div class="col-md-6 d-none-resp"></div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-lock"></i></label>
                                          <input
                                            v-model="form2.pass"
                                            v-validate="'required'"
                                            name="pass"
                                            ref="password"
                                            data-vv-as="contraseña"
                                            type="password"
                                            placeholder="Contraseña"
                                            :class="{ 'vue-error': errors.first('form2.pass') }"
                                            key="15"
                                            maxlength="50"
                                          />

                                          <!-- <password v-model="form2.pass"/> -->
                                          <FormError :error="errors.first('form2.pass')"> </FormError>
                                          <password v-model="form2.pass" :strength-meter-only="true" />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                          <label><i class="fas fa-lock"></i></label>
                                          <input
                                            v-model="form2.repass"
                                            v-validate="'required|confirmed:password'"
                                            name="repass"
                                            data-vv-as="confirmar contraseña"
                                            type="password"
                                            placeholder="Confirme Contraseña"
                                            :class="{ 'vue-error': errors.first('form2.repass') }"
                                            key="16_"
                                            maxlength="50"
                                          />
                                          <FormError :error="errors.first('form2.repass')"> </FormError>
                                          <password v-model="form2.repass" :strength-meter-only="true" />
                                        </div>
                                        <div class="col-md-12">
                                          <button
                                            type="submit"
                                            alt="Completar registro" aria-label="Completar registro"
                                            class="btn btn-default-grad-purple-fill btn-resp-v mb-1 mt-3"
                                            style="float: right; min-width: 180px; padding: 5px 20px !important"
                                            :class="[verify ? 'verify-class' : 'normal-class']"
                                          >
                                            <span v-if="!verify" class="spinner-span"
                                              >Completar registro
                                              <i
                                                class="fas fa-arrow-alt-circle-right spinner-i"
                                                style="top: 9px"
                                              ></i
                                            ></span>
                                            <div
                                              v-else
                                              class="spinner-border text-secondary"
                                              style="max-height: 30px"
                                              role="status"
                                            >
                                              <span class="sr-only">Loading...</span>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                          </div>
                      </div>
                      <!-- tab register login cart END ------------------------------------------------- -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12"></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState, mapGetters, mapMutations } from "vuex";
import HeaderNavComponent from "../HeaderNavComponent";
import FormError from "../FormError";
import Password from "vue-password-strength-meter";
import {
  formatNumber,
  toArray,
  setPriceService,
  setTotalPriceService,
  setMasterTotalPriceService,
  returnQuantityService,
  changeSeparator,
  returnAllServices,
  returnCantidadService,
  onlyNumberAndDash,
  onlyNumber,
  precioConDescuento,
  getCalculo_price_with_cupon,
  get_valor_por_nombre_periodicidad
} from "@/helpers";
export default {
  components: {
    Password,
    HeaderNavComponent,
    FormError,
  },
  props: ["cart", "refresh_load"],
  data: () => ({
    verify: false,
    form1: {
      rut_username: "",
      contrasena: "",
    },
    form2: {
      nombre: "",
      rut: "",
      email: "",
      direccion: "",
      telefono: "",
      email_tecnico: "",

      facturacion_nombre: "",
      facturacion_rut: "",
      facturacion_email: "",
      facturacion_direccion: "",
      facturacion_telefono: "",
      facturacion_region: "",
      facturacion_comuna: "",
      facturacion_giro: "",
      username: "",
      pass: "",
      repass: "",
      pathUrl: "",
    },
    Regiones_: [],
    Comunas_: [],
    colorAuthBoton: 'signup',
    cupon_query: null
  }),
  computed: {
    ...mapGetters(["getUser", "getResume", "getCart"]),
    // ...mapState(["cart"]),
    returnListAllServices(){
      // console.log("getCart: ", this.getCart);
      return this.returnAllServices(this.cart)
    },
    observeServicePrices() {
      return this.getCart.map(item => item.id);
    },
    tieneHosting() {
      return this.returnAllServices(this.cart).some(item => item.category_ && item.category_.nombre === 'Hosting');
    }
    },
  watch:{
    getCart:{
      handler(val, oldVal) {
        this.$forceUpdate();
      },
      deep: true
    },
    refresh_load(){
      this.$forceUpdate();
    },
    observeServicePrices: {
    handler(val, oldVal) {
      // console.log("se edita un objeto del servicio");

      this.$forceUpdate();

    },
    deep: true
  }
  },
  created(){
    this.Regiones_ = this.Regiones_Ciudades();
  },
  // watch: {
  //   "form2.repass"(val) {
  //     if (val !== this.form2.pass) {
  //       console.log(val);
  //       this.$validator.errors.add({
  //         field: "repass",
  //         msg: "Las contraseñas no coinciden",
  //         scope: "form2",
  //       });
  //     }
  //   },
  // },
  methods: {
    ...mapMutations(["logout", "setSelected", "deleteServiceAditionalCart"]),
    ...mapActions(["login", "register"]),
    formatNumber,
    toArray,
    setPriceService,
    setTotalPriceService,
    setMasterTotalPriceService,
    returnQuantityService,
    changeSeparator,
    returnAllServices,
    returnCantidadService,
    onlyNumberAndDash,
    onlyNumber,
    precioConDescuento,
    getCalculo_price_with_cupon,
    get_valor_por_nombre_periodicidad,

        // funcion que toma los datos del pago desde la respuesta URL
    checkResponse() {
    // sin no es una transferencian
    if(this.$route.query.cupon){
      this.cupon_query = {code: this.$route.query.cupon} ;
      if(this.$route.query.cupon_periodicidad) this.cupon_query.id_periodicidad = this.$route.query.cupon_periodicidad;
    }
    },
    transferData() {
      this.$Progress.start();
      this.form2.facturacion_nombre = this.form2.nombre;
      this.form2.facturacion_rut = this.form2.rut;
      this.form2.facturacion_email = this.form2.email;
      this.form2.facturacion_direccion = this.form2.direccion;
      this.form2.facturacion_telefono = this.form2.telefono;
      this.$Progress.finish();
      this.$toast.success("Información copiada.");
    },
    editItem(index) {
      this.$emit("edit", index);
    },
    deleteService_(index) {
      this.$root.$emit("deleteService", index);
    },
    back() {
      this.$emit("back");
    },
    login_() {
      this.$Progress.start();
      this.$validator.validateAll("form1").then((esValido) => {
        if (esValido) {
          this.verify = true;
          this.login(this.form1).then((res) => {
            if (res?.type == "success") {
              this.$toast.success(res?.cliente?.nombre + " Logeado correctamente.");
              this.$Progress.finish();
            } else {
              this.$validator.errors.add({
                field: "rut_username",
                msg: res.message,
                scope: "form1",
              });

              this.$toast.error("Error en los campos ingresados");
              this.$Progress.fail();
            }
            this.verify = false;
          });
        } else {
          this.$toast.error("Error de login");
          this.$Progress.fail();
          this.$Progress.finish();
          this.verify = false;
        }
        //
      });
    },

    deleteServiceAditional(item){
      
      this.deleteServiceAditionalCart(item);
    },
    logout_() {
      this.logout();
    },
    goToCategory() {
      this.$emit("goToCategory");
    },

    register_() {
      this.$Progress.start();
      this.$validator.validateAll("form2").then((esValido) => {
        if (esValido) {
          this.verify = true;
          this.register(this.form2).then((res) => {
            if (res?.type == "success") {
              var nav0 = document.getElementById("nav-0");
              var nav1 = document.getElementById("nav-1");
              nav1.classList.remove("active");
              nav1.classList.remove("show");
              nav0.classList.add("active");
              nav0.classList.add("show");
              window.scrollTo(0, 0);
              this.$toast.success(res?.cliente[0]?.nombre + " Registrado correctamente.");
              this.$Progress.finish();
            } else {
              for (const [index, value] of Object.entries(res)) {
                this.$validator.errors.add({
                  field: index,
                  msg: value[0],
                  scope: "form2",
                });
              }

              this.$toast.error("Error en los campos ingresados");
              this.$Progress.fail();
            }
            this.verify = false;
          });
        } else {
          this.$toast.error("Error de registro");
          this.$Progress.fail();
          this.verify = false;
        }
      });
    },
    City_Updater(){
      if(this.form2.facturacion_region != ""){
        let idx=0;
        let delreg=true;
        this.Regiones_.forEach(reg => {
          if(this.form2.facturacion_region == reg){
            this.Comunas_ = this.Regiones_Ciudades(idx);
            delreg=false;
          }
          idx++;
        });

        if(delreg){
          this.form2.facturacion_region = "";
          this.form2.facturacion_comuna = "";
        }
      }
    },
    City_Check(){
      if(this.form2.facturacion_comuna != ""){
        let delcom=true;
        this.Comunas_.forEach(com => {
          if(this.form2.facturacion_comuna == com){
            delcom=false;
          }
        });

        if(delcom){
          this.form2.facturacion_comuna = "";
        }
      }
    },
    Regiones_Ciudades(region=-1, ciudad=-1){
      let Chile = [
        {
          "NombreRegion": "Arica y Parinacota",
          "comunas": ["Arica", "Camarones", "Putre", "General Lagos"]
        },{
          "NombreRegion": "Tarapacá",
          "comunas": ["Iquique", "Alto Hospicio", "Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"]
        },{
          "NombreRegion": "Antofagasta",
          "comunas": ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal", "Calama", "Ollagüe", "San Pedro de Atacama", "Tocopilla", "María Elena"]
        },{
          "NombreRegion": "Atacama",
          "comunas": ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Alto del Carmen", "Freirina", "Huasco"]
        },{
          "NombreRegion": "Coquimbo",
          "comunas": ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Paiguano", "Vicuña", "Illapel", "Canela", "Los Vilos", "Salamanca", "Ovalle", "Combarbalá", "Monte Patria", "Punitaqui", "Río Hurtado"]
        },{
          "NombreRegion": "Valparaíso",
          "comunas": ["Valparaíso", "Casablanca", "Concón", "Juan Fernández", "Puchuncaví", "Quintero", "Viña del Mar", "Isla de Pascua", "Los Andes", "Calle Larga", "Rinconada", "San Esteban", "La Ligua", "Cabildo", "Papudo", "Petorca", "Zapallar", "Quillota", "Calera", "Hijuelas", "La Cruz", "Nogales", "San Antonio", "Algarrobo", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "San Felipe", "Catemu", "Llaillay", "Panquehue", "Putaendo", "Santa María", "Quilpué", "Limache", "Olmué", "Villa Alemana"]
        },{
          "NombreRegion": "Región del Libertador Gral. Bernardo O’Higgins",
          "comunas": ["Rancagua", "Codegua", "Coinco", "Coltauco", "Doñihue", "Graneros", "Las Cabras", "Machalí", "Malloa", "Mostazal", "Olivar", "Peumo", "Pichidegua", "Quinta de Tilcoco", "Rengo", "Requínoa", "San Vicente", "Pichilemu", "La Estrella", "Litueche", "Marchihue", "Navidad", "Paredones", "San Fernando", "Chépica", "Chimbarongo", "Lolol", "Nancagua", "Palmilla", "Peralillo", "Placilla", "Pumanque", "Santa Cruz"]
        },{
          "NombreRegion": "Región del Maule",
          "comunas": ["Talca", "Constitución", "Curepto", "Empedrado", "Maule", "Pelarco", "Pencahue", "Río Claro", "San Clemente", "San Rafael", "Cauquenes", "Chanco", "Pelluhue", "Curicó", "Hualañé", "Licantén", "Molina", "Rauco", "Romeral", "Sagrada Familia", "Teno", "Vichuquén", "Linares", "Colbún", "Longaví", "Parral", "Retiro", "San Javier", "Villa Alegre", "Yerbas Buenas"]
        },{
          "NombreRegion": "Región del Biobío",
          "comunas": ["Concepción", "Coronel", "Chiguayante", "Florida", "Hualqui", "Lota", "Penco", "San Pedro de la Paz", "Santa Juana", "Talcahuano", "Tomé", "Hualpén", "Lebu", "Arauco", "Cañete", "Contulmo", "Curanilahue", "Los Álamos", "Tirúa", "Los Ángeles", "Antuco", "Cabrero", "Laja", "Mulchén", "Nacimiento", "Negrete", "Quilaco", "Quilleco", "San Rosendo", "Santa Bárbara", "Tucapel", "Yumbel", "Alto Biobío", "Chillán", "Bulnes", "Cobquecura", "Coelemu", "Coihueco", "Chillán Viejo", "El Carmen", "Ninhue", "Ñiquén", "Pemuco", "Pinto", "Portezuelo", "Quillón", "Quirihue", "Ránquil", "San Carlos", "San Fabián", "San Ignacio", "San Nicolás", "Treguaco", "Yungay"]
        },{
          "NombreRegion": "Región de la Araucanía",
          "comunas": ["Temuco", "Carahue", "Cunco", "Curarrehue", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Melipeuco", "Nueva Imperial", "Padre las Casas", "Perquenco", "Pitrufquén", "Pucón", "Saavedra", "Teodoro Schmidt", "Toltén", "Vilcún", "Villarrica", "Cholchol", "Angol", "Collipulli", "Curacautín", "Ercilla", "Lonquimay", "Los Sauces", "Lumaco", "Purén", "Renaico", "Traiguén", "Victoria", ]
        },{
          "NombreRegion": "Región de Los Ríos",
          "comunas": ["Valdivia", "Corral", "Lanco", "Los Lagos", "Máfil", "Mariquina", "Paillaco", "Panguipulli", "La Unión", "Futrono", "Lago Ranco", "Río Bueno"]
        },{
          "NombreRegion": "Región de Los Lagos",
          "comunas": ["Puerto Montt", "Calbuco", "Cochamó", "Fresia", "Frutillar", "Los Muermos", "Llanquihue", "Maullín", "Puerto Varas", "Castro", "Ancud", "Chonchi", "Curaco de Vélez", "Dalcahue", "Puqueldón", "Queilén", "Quellón", "Quemchi", "Quinchao", "Osorno", "Puerto Octay", "Purranque", "Puyehue", "Río Negro", "San Juan de la Costa", "San Pablo", "Chaitén", "Futaleufú", "Hualaihué", "Palena"]
        },{
          "NombreRegion": "Región Aisén del Gral. Carlos Ibáñez del Campo",
          "comunas": ["Coihaique", "Lago Verde", "Aisén", "Cisnes", "Guaitecas", "Cochrane", "O’Higgins", "Tortel", "Chile Chico", "Río Ibáñez"]
        },{
          "NombreRegion": "Región de Magallanes y de la Antártica Chilena",
          "comunas": ["Punta Arenas", "Laguna Blanca", "Río Verde", "San Gregorio", "Cabo de Hornos (Ex Navarino)", "Antártica", "Porvenir", "Primavera", "Timaukel", "Natales", "Torres del Paine"]
        },{
          "NombreRegion": "Región Metropolitana de Santiago",
          "comunas": ["Cerrillos", "Cerro Navia", "Conchalí", "El Bosque", "Estación Central", "Huechuraba", "Independencia", "La Cisterna", "La Florida", "La Granja", "La Pintana", "La Reina", "Las Condes", "Lo Barnechea", "Lo Espejo", "Lo Prado", "Macul", "Maipú", "Ñuñoa", "Pedro Aguirre Cerda", "Peñalolén", "Providencia", "Pudahuel", "Quilicura", "Quinta Normal", "Recoleta", "Renca", "San Joaquín", "San Miguel", "San Ramón", "Santiago", "Vitacura", "Puente Alto", "Pirque", "San José de Maipo", "Colina", "Lampa", "Tiltil", "San Bernardo", "Buin", "Calera de Tango", "Paine", "Melipilla", "Alhué", "Curacaví", "María Pinto", "San Pedro", "Talagante", "El Monte", "Isla de Maipo", "Padre Hurtado", "Peñaflor"]
        }
      ]
      
      let regiones = [];
      if(region == -1){
        Chile.forEach(reg => {
          regiones.push(reg.NombreRegion);
        });
        return regiones;
      }

      if(region > -1){
        return Chile[region].comunas;
      }

      if((region > -1) && (ciudad > -1)){
        return Chile[region].comunas[ciudad];
      }
    },
    activeColorAuth(value){
      // console.log("activeColorAuth: ", bool);
      this.colorAuthBoton = value;
    },
    generateRandomKey() {
      // Genera un número aleatorio entre 1 y 1000 (puedes ajustar el rango según tus necesidades)
      return Math.floor(Math.random() * 1000);
    }
  },
};
</script>

<style scoped>
.vue-error {
  border-color: #dc3545 !important;
}
.title-bar-left2 {
  text-align: left;
  margin-top: 33px;
  padding: 10px 30px;
  font-size: 17px;
}

.title-bar-right2 {
  text-align: right;
  margin-top: 33px;
  padding: 10px 30px;
  font-size: 17px;
}

.cd-filter-block label i {
  top: 20px !important;
}

.title_tabs {
  margin-bottom: 15px;
}

.cd-filter-block .col-md-6 {
  margin-bottom: 10px;
}

.check-container {
  box-shadow: 0 5px 15px 0 rgb(110 110 110 / 10%);
  padding: 65px 80px;
  background: white;
  border-radius: 15px;
  margin-right: 0px;
  margin-left: 0px;
}

.content_loginRegistro{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}

.content_loginRegistro_ingresar{
  width: 200px;
}

.box_service{
  position: relative !important;
}
.box_service_btn_deleteService{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1px 10px;
    z-index: 1000;
}

@media(max-width: 600px){
  .content_loginRegistro_ingresar{
    width: 145px;
  }
}
/* start boton de registro superior */
.colorAuthBoton{
    background: var(--primary-color) !important;
    position: relative !important;
}

.colorAuthBoton::after{
    opacity: 1 !important;
    border-top-color: var(--primary-color) !important;
}
.colorAuthBoton div span{
  color: var(--white-color) !important;
}
/* Start boton de registro bajo */
.register_absolute{
  text-align: center;
    position: absolute !important;
    bottom: -20px;
    width: 100%;
    padding: 0 15% !important;
    font-weight: 600;
    color: var(--dark-color);
}


/* cupon style */

.listCart_message_cupon{
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.listCart_message_cupon_text{
  margin: 0 5px;
}

.priceAndCupon{
  flex-direction: column;
}
.priceAndCupon_priceBeforeCupon{
  text-decoration: line-through;
  font-size: 12px;
}

/* cupon icon start */

.sparkle {
  position: relative;
  z-index: 10;
  width: 1rem;
}

.sparkle .path {
  fill: currentColor;
  stroke: currentColor;
  transform-origin: center;
  color: #3696ce !important;
  animation: path 2.5s linear 1.5s infinite;
}



/* .button:is(:hover, :focus) .sparkle .path {
  animation: path 1.5s linear 0.5s infinite;
} */

.sparkle .path:nth-child(1) {
  --scale_path_1: 1.2;
}
.sparkle .path:nth-child(2) {
  --scale_path_2: 1.2;
}
.sparkle .path:nth-child(3) {
  --scale_path_3: 1.2;
}

@keyframes path {
  0%,
  34%,
  71%,
  100% {
    transform: scale(1);
  }
  17% {
    transform: scale(var(--scale_path_1, 1));
  }
  49% {
    transform: scale(var(--scale_path_2, 1));
  }
  83% {
    transform: scale(var(--scale_path_3, 1));
  }
}
/* cupon icon end */


@media(max-width: 1199px){
  .register_absolute{
    bottom: -58px;

  }
}

@media(max-width: 991px){
  .register_absolute{
    bottom: -20px;

  }
}

@media(max-width: 767px){
  .register_absolute{
    bottom: -40px;

  }
}

@media(max-width: 540px){
  .register_absolute{
    bottom: -52px;
    padding: 0px 10% !important;

  }
  .title_tabs_infoPersonal{
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
  .title_tabs_infoPersonal_empty-cart{
    text-align: left !important;
    margin: 0px !important;
  }
}

@media(max-width: 375px){
  .register_absolute{
    padding: 0 7%!important;

  }
}

.box_service_btn_deleteService{
  margin: 10px 15px;
}
</style>